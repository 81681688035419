<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input class="input uppercase"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Quote No."
                v-model="innerValue.miscQuoteNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input uppercase"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Customer"
                v-model="innerValue.customerName"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input class="input uppercase"
                :class="[`input-${$user.info.theme}`]"
                type="text"
                placeholder="Invoice No."
                v-model="innerValue.invoiceNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="buttons has-addons">
            <span class="button"
              :class="{ 'is-primary is-selected' : innerValue.active === 0}"
              @click="changeActive(0)">
              <span class="icon is-small">
                <i class="mdi mdi-adjust" />
              </span>
              <span>Both</span>
            </span>
            <span class="button"
              :class="{ 'is-success is-selected' : innerValue.active === 1}"
              @click="changeActive(1)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span class="button"
              :class="{ 'is-danger is-selected' : innerValue.active === 2}"
              @click="changeActive(2)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { Columns } from './columns'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'MiscQuoteListSideMenu',
  components: {
    AppSideMenu
  },
  props: {
    value: null
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value)
    }
  },
  computed: {
    columns() {
      return Columns
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.$eventHub.$on('filterReset', () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  mounted() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
    },
    changeActive(value) {
      this.innerValue.active = value
    }
  }
}
</script>
